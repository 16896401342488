import React from 'react';
import withDataroomGlobalProps from '@/dataroom/infrastructure/next/withDataroomGlobalProps';
import Layout from '@/dataroom/ui/components/Landing/17g5';
import PageNotFoundComponent from '@/Framework/UI/Pages/PageNotFound';
import pageNotFoundRedirectCallback from '@/dataroom/infrastructure/next/pageNotFoundRedirectCallback';

export const getServerSideProps = withDataroomGlobalProps(pageNotFoundRedirectCallback);

const PageNotFound = () => (
  <Layout>
    <PageNotFoundComponent />
  </Layout>
);

export default PageNotFound;
